*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: white;
  box-sizing: border-box;
  font-weight: 400;
  width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

a {
  text-decoration: none;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  opacity: 0;
}

*::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}
